import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/flayout"
import Blog from "../components/blog";

export default class BlogTagList extends React.Component {
  render() {
    const posts = this.props.data.allWordpressPost.edges;
    const tags = this.props.data.allWordpressCategory.edges;
    console.log(tags,'tags');
    const categoryName = this.props.pageContext.tag;
    return (
      <Layout darkHeader={true} seoFields={{...seoFields,title: 'Blog - '+categoryName}}>
        <Blog posts={posts} tags={tags} title={'Blog - '+categoryName} index={0} numPages={1} showPagination={false} location={this.props.location}/>
      </Layout>
    )
  }
}

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "Blog" 
}

export const blogListQuery = graphql`
  query blogListQuery($tag: String!) {
    allWordpressPost(
        sort: { fields: [date], order: DESC }
        filter: {categories: {elemMatch:{name:{eq:$tag}}}}
    )  {
      edges {
        node {
          title
          excerpt
          slug
          content
          date
          path
          featured_media{ 
            localFile {
              childImageSharp {
                fluid(maxWidth: 220) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWordpressCategory(filter: {count:{gt:0}})
      {
        edges {
          node {
            id
            name
            path
          }
        }
      }
  }
`